import React, { useState, useEffect } from "react"
import styled from "styled-components"

import media, { sizes } from "../../styles/media"
import { ContactForm } from "./ContactForm"
import {
  GridContainer,
  PlusIcon,
  MinusIcon,
  SquareAspectRatioBox,
} from "../common"

const MobileSection = styled.section`
  display: flex;
  grid-column: 4;
  justify-self: end;

  ${media.tablet`
    grid-column: 12;
    align-self: flex-start;
    line-height: 52px;
    `}
`

const ContactEntryButton = styled.div`
  cursor: pointer;
  display: block;
  &:hover {
    color: ${props => props.theme.red};
  }
`

const ToggleButton = styled.div`
  ${media.tablet`
    
 `}
`

const Section = styled(GridContainer).attrs({ as: "section" })`
  grid-column: 1 / -1;
  padding: 0;

  max-height: ${props => (props.show ? "100%" : 0)};
  transition: max-height 750ms ease;
  overflow: hidden;

  ${media.tablet`
    display: grid;
    padding: 0;
  `}
`

const ContactEntry = styled.article`
  grid-column: auto / span 2;
  margin-bottom: 10px;
  height: 100%;

  ${media.tablet`
    display: flex;
    font-size: 20px;
    grid-column: auto / span 4;
    justify-content: space-around;
    height: 100%;
  `}

  > div {
    flex: 1;
  }
`
const ContactFormWrapper = styled.div`
  z-index: 3;
  overflow: visible;
  position: absolute;
`

export const ContactList = ({ entries }) => {
  const [selectedEntry, setSelectedEntry] = useState(null)
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    if (window.innerWidth >= sizes.tablet) {
      setIsExpanded(true)
    }
  }, [])
  return (
    <>
      <MobileSection>
        <ToggleButton onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <MinusIcon /> : <PlusIcon />}
        </ToggleButton>
      </MobileSection>

      {selectedEntry && (
        <ContactFormWrapper>
          <ContactForm
            recipientEmail={selectedEntry.email}
            recipientName={selectedEntry.name}
            hide={() => setSelectedEntry(null)}
          />
        </ContactFormWrapper>
      )}

      <Section show={isExpanded}>
        {entries.map(entry => (
          <ContactEntry>
            {entry.photo && (
              <div>
                <SquareAspectRatioBox>
                  <img src={entry.photo.file.url} alt={entry.name} />
                </SquareAspectRatioBox>
              </div>
            )}

            <div
              css={`
                span {
                  display: block;
                }
              `}
            >
              <ContactEntryButton
                onClick={() => {
                  setSelectedEntry(entry)
                }}
              >
                <span>{entry.name}</span>
              </ContactEntryButton>
              <span>{entry.title}</span>
              <span>{entry.number}</span>
            </div>
          </ContactEntry>
        ))}
      </Section>
    </>
  )
}
