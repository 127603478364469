import React from "react"
import styled from "styled-components"
import media from "../../styles/media"

const MapSection = styled.section`
  height: 100%;
  font-size: 20px;
  grid-column: 1 / -1;

  iframe {
    height: 100%;
    width: 100%;
  }

  ${media.tablet`
    grid-column: 5 / -1;
    align-self: start;
  `}
`

const ContactLeft = styled.section`
  font-family: StanleyRegular, serif;
  grid-column: 1 / span 3;
  align-self: start;

  div {
    a {
      text-decoration: none;
      color: ${props => props.theme.black};
    }
  }
`

const Title = styled.h3`
  font-weight: normal;
  font-family: neue-haas-grotesk-text, sans-serif;
  color: ${props => props.theme.lightGrey};
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 30px;
  text-transform: uppercase;
  margin: 0 auto;

  ${media.tablet`
    letter-spacing: 1.33px;
  `}
`

const Info = styled.span`
  margin: 0 auto;
  font-size: 16px;

  ${media.tablet`
    font-size:24px;
  `}

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.red};
    }
  }
`

const Address = styled.h2`
  font-weight: normal;
  margin: 0 auto;
  font-size: 24px;
  line-height: 30px;

  address {
    font-style: normal;

    p {
      margin: 0;
    }
  }
`

const InfoSection = styled.section`
  margin-bottom: 30px;
`

const AddressSection = styled.section`
  margin-bottom: 50px;
`

export const ContactInfo = ({
  officeAddress,
  phoneTitle,
  phoneInfo,
  faxTitle,
  faxInfo,
  inquiriesTitle,
  inquiriesInfo,
}) => {
  return (
    <>
      <ContactLeft aria-label="Columbia Artists Contact Info">
        <AddressSection aria-label="Address">
          <Address>
            <address>
              <div
                dangerouslySetInnerHTML={{
                  __html: officeAddress,
                }}
              ></div>
            </address>
          </Address>
        </AddressSection>
        <InfoSection>
          <Title>{phoneTitle}</Title>
          <Info>{phoneInfo}</Info>
        </InfoSection>
        <InfoSection>
          <Title>{faxTitle}</Title>
          <Info>{faxInfo}</Info>
        </InfoSection>
        <InfoSection>
          <Title>{inquiriesTitle}</Title>
          <Info>
            <a href="mailto:info@columbia-artists.com">{inquiriesInfo}</a>
          </Info>
        </InfoSection>
      </ContactLeft>
      <MapSection aria-label="Columbia Artists Location">
        <iframe
          title="demo"
          width="843"
          height="327"
          frameborder="0"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3021.7783858850557!2d-73.98378958449197!3d40.76689874219185!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258f771d21c57%3A0xd92e0c6991c242d3!2sColumbia%20Artists!5e0!3m2!1sen!2sus!4v1575645935478!5m2!1sen!2sus"
          allowfullscreen
        />
      </MapSection>
    </>
  )
}
