import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { GridContainer, Layout, RuleLine } from "../components/common/"
import media from "../styles/media"
import SEO from "../components/common/SEO"
import { ContactList, ContactInfo } from "../components/contact"

const Wrapper = styled(GridContainer)`
  align-items: baseline;
  margin-bottom: 60px;
  margin-top: 130px;
  position: relative;
`

const Title = styled.h2`
  font-family: StanleyRegular, serif;
  font-weight: normal;
  grid-column: 1 / span 3;
  line-height: 36px;

  ${media.tablet`
    font-size: 45px;
    grid-column: 1 / span 9;
    line-height: 52px;
    margin-top: 0;
  `}
`

const Contact = ({ data }) => {
  const pageContent = data.allContentfulContactPage.edges[0].node

  const {
    columbiaArtists,
    bookingDepartment,
    columbiaTheatricals,
    officeAddressLongText,
    phoneTitle,
    phoneInfo,
    faxTitle,
    faxInfo,
    inquiriesTitle,
    inquiriesInfo,
    departments,
  } = pageContent

  const sections = [
    { title: "Columbia Artists", entries: columbiaArtists },
    { title: "Booking Department", entries: bookingDepartment },
    { title: "Columbia Theatricals", entries: columbiaTheatricals },
  ]

  const sections2 = departments

  const imageUrl =
    pageContent.contactPageMetaContent &&
    pageContent.contactPageMetaContent.metaImage
      ? pageContent.contactPageMetaContent.metaImage.fileUrl
      : undefined

  const twitterImageUrl =
    pageContent.contactPageMetaContent &&
    pageContent.contactPageMetaContent.metaImageTwitter
      ? pageContent.contactPageMetaContent.metaImageTwitter.file.url
      : undefined

  return (
    <Layout headerTheme="dark">
      <Wrapper>
        <SEO
          title="Columbia Artists - Contact"
          path="/contact"
          description={
            pageContent.contactPageMetaContent
              ? pageContent.contactPageMetaContent.metaDescription
                  .metaDescription
              : undefined
          }
          imageUrl={imageUrl && "https:" + imageUrl}
          twitterImageUrl={twitterImageUrl && "https:" + twitterImageUrl}
        />

        <ContactInfo
          officeAddress={officeAddressLongText.childMarkdownRemark.html}
          phoneTitle={phoneTitle}
          phoneInfo={phoneInfo}
          faxTitle={faxTitle}
          faxInfo={faxInfo}
          inquiriesTitle={inquiriesTitle}
          inquiriesInfo={inquiriesInfo}
        />

        {sections2.map(department => (
          <>
            <RuleLine />
            <Title>{department.name}</Title>
            <ContactList entries={department.contactEntries} />
          </>
        ))}
      </Wrapper>
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  {
    allContentfulContactPage {
      edges {
        node {
          contactPageMetaContent {
            metaDescription {
              metaDescription
            }
            metaImageTwitter {
              file {
                url
              }
            }
            metaImage {
              file {
                url
              }
            }
          }
          officeAddressLongText {
            childMarkdownRemark {
              html
            }
          }
          faxTitle
          faxInfo
          phoneTitle
          phoneInfo
          inquiriesTitle
          inquiriesInfo
          columbiaArtists {
            ...ContentfulContactEntry
          }
          bookingDepartment {
            ...ContentfulContactEntry
          }
          columbiaTheatricals {
            ...ContentfulContactEntry
          }
          departments {
            name
            contactEntries {
              ...ContentfulContactEntry
            }
          }
        }
      }
    }
  }
`
