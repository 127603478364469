import React, { useState } from "react"
import styled from "styled-components"
import media from "../../styles/media"
import axios from "axios"

import { CloseIcon } from "../icons"

const SUBMISSION_URL = "/.netlify/functions/handle-contact-form"

const Modal = styled.div`
  box-shadow: 2px 2px 5px ${props => props.theme.lightGrey3};
  background-color: white;
  position: fixed;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  min-height: 50vh;

  ${media.tablet`
    top: 50%
    transform: translateX(-50%) translateY(-50%);
    width: 80vw;
    max-width: 800px;
  `};
`

const Form = styled.form`
  opacity: ${props => (props.isLoading ? 0.75 : 1)};
  padding: 60px 50px 30px 50px;
`

const InfoInput = styled.input`
  margin: 10px 0;
  padding: 5px 0;
  font-size: 20px;
  font-family: StanleyRegular, serif;
  border: none;
  border-bottom: 1px solid black;
  width: 100%;
`

const MessageInput = styled(InfoInput).attrs({ as: "textarea" })`
  margin-top: -5px;
  height: 100px;
  resize: none;
`

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 18px;

  svg {
    g,
    path {
      position: absolute;
    }
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const Button = styled.button`
  color: ${props =>
    props.isLoading ? props.theme.lightGrey3 : props.theme.white};
  background-color: ${props => props.theme.black};
  width: 95px;
  height: 45px;
  border: 2px solid black;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 20px;
  text-transform: uppercase;
`

const Recipient = styled.span`
  color: ${props => props.theme.lightGrey3};
  display: block;
`

const AfterSubmitMessage = styled.div`
  font-size: 20px;
  font-family: StanleyRegular, serif;
  justify-content: center;
  text-align: center;
  margin: 0 30px 0 30px;

  ${media.tablet`
    
  `}
`

export const ContactForm = ({ hide, recipientEmail, recipientName }) => {
  if (!recipientEmail) {
    recipientEmail = process.env.GATSBY_DEFAULT_CONTACT_EMAIL
  }

  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isError, setIsError] = useState(false)

  const [name, setName] = useState("")
  const [company, setCompany] = useState("")
  const [senderEmail, setSenderEmail] = useState("")
  const [message, setMessage] = useState("")
  const [subject, setSubject] = useState("")

  const handleSubmit = e => {
    e.preventDefault()
    setIsLoading(true)

    const sendGridFields = {
      name,
      company,
      recipientEmail,
      senderEmail,
      subject,
      message,
    }

    axios
      .post(SUBMISSION_URL, sendGridFields)
      .then(() => {
        setIsLoading(false)
        setIsSubmitted(true)
      })
      .catch(error => {
        console.error(error)
        setIsError(true)
        setIsLoading(false)
      })
  }

  return (
    <Modal isLoading={isLoading} onSubmit={handleSubmit}>
      <CloseIconWrapper>
        <CloseIcon onClick={hide} />
      </CloseIconWrapper>

      {isError && (
        <AfterSubmitMessage
          css={`
            color: ${props => props.theme.red};
            margin-top: 30px;
          `}
        >
          An error occurred when submitting your message. Please try again.
        </AfterSubmitMessage>
      )}

      {isSubmitted && (
        <AfterSubmitMessage
          css={`
            margin: 50px 30px 0 30px;
          `}
        >
          Your message has been received. A member of the Columbia Artists team
          will be in touch via email.
        </AfterSubmitMessage>
      )}

      {!isSubmitted && (
        <Form isLoading={isLoading}>
          <InfoInput
            placeholder="Name"
            type="text"
            name="name"
            value={name}
            onChange={e => setName(e.target.value)}
          />

          <InfoInput
            placeholder="Company"
            type="text"
            name="company"
            value={company}
            onChange={e => setCompany(e.target.value)}
          />

          <InfoInput
            placeholder="Your Email Address"
            type="text"
            name="From email"
            value={senderEmail}
            onChange={e => setSenderEmail(e.target.value)}
          />

          <InfoInput
            placeholder="Subject"
            type="text"
            name="subject"
            value={subject}
            onChange={e => setSubject(e.target.value)}
          />

          <MessageInput
            name="message"
            type="text"
            value={message}
            onChange={e => setMessage(e.target.value)}
          />

          {recipientName && <Recipient>Sending to {recipientName}</Recipient>}

          <ButtonContainer>
            <Button type="submit">Send</Button>
          </ButtonContainer>
        </Form>
      )}
    </Modal>
  )
}
